
import { getDirectoryObject } from '../../../../../mixins/main'

<template lang="pug">
include ../../../../../configs/template
div.row.text-left.pa-5
  div.col-sm-12.col-md-6.col-xl-4
    +data-info('crewingManagerFullName', 'fullName')
  div.col-sm-12.col-md-6.col-xl-4
    +data-info('crewingCompany', 'sailorDocument.crewing_company.name_ukr')
  div.col-sm-12.col-md-6.col-xl-4
    +data-info('position', 'sailorDocument.managerType')
  div.col-sm-12.col-md-6.col-xl-4
    +data-info('affiliate', 'affiliate')
  div.col-sm-12.col-md-6.col-xl-4
    +data-info('city', 'sailorDocument.userprofile.city')
  div(v-if="sailorDocument.userprofile.contact_info.length").col-12.p-0
    div(v-for="record of sailorDocument.userprofile.contact_info" :key="record.id")
      div(v-if="['1', 'phone_number', 1].includes(record.type_contact)").col-sm-12.col-md-6.col-xl-4
        +data-info('phoneNumber', 'record.value')
      div(v-if="['4', 'telegram', 4].includes(record.type_contact)").col-sm-12.col-md-6.col-xl-4
        +data-info('telegram', 'record.value')
      div(v-if="['5', 'viber', 3].includes(record.type_contact)").col-sm-12.col-md-6.col-xl-4
        +data-info('viber', 'record.value')
      div(v-if="['2', 'email', 2].includes(record.type_contact)").col-sm-12.col-md-6.col-xl-4
        +data-info('email', 'record.value')
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BackOfficeAgentGroupsInfo',
  props: {
    sailorDocument: Object
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    fullName () {
      return `${this.sailorDocument.last_name} ${this.sailorDocument.first_name} ${this.sailorDocument.userprofile.middle_name || ''}`
    },
    affiliate () {
      return `${this.getDirectoryObject({ value: 'affiliate', id: this.sailorDocument.userprofile.branch_office }).name_ukr}(${this.sailorDocument.userprofile.branch_office})`
    }
  }
}

</script>>
